/* eslint-disable jsx-a11y/alt-text */
import { initReactI18next, useTranslation } from 'react-i18next';
import './App.css';
import i18next from 'i18next';
import { Footer } from './Common/Footer';
import { Header } from './Common/Header';
import { Navigator } from './Common/Navigator';
import en from './Translations/en.json';
import fr from './Translations/fr.json';
import ar from './Translations/ar.json';
import arFlag from './Assets/Flags/ar.png';
import frFlag from './Assets/Flags/fr.png';
import enFlag from './Assets/Flags/en.png';
import { Language } from './Types/Language';

export const AppLanguages: Language[] = [
  {
    name: 'AR',
    slug: 'ar',
    flag: arFlag
  },
  {
    name: 'EN',
    slug: 'en',
    flag: enFlag
  },
  {
    name: 'FR',
    slug: 'fr',
    flag: frFlag
  }
];

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      fr,
      ar
    },
    lng: 'en',
    fallbackLng: AppLanguages.map((lang) => lang.slug.toLowerCase()),
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const App = () => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="App">
      <Header />
      <Navigator />
      <Footer />
    </div>
  );
};

export default App;
