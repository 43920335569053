import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppTitle } from '../../Common/AppTitle';
import { AppContainer } from '../../Common/AppContainer';
import { Tab, Tabs } from '../../Common/Tabs';
import { Candidate } from '../../Types/Candidate';
import { CandidateCard } from '../../Common/CandidateCard';
import { AppButton } from '../../Common/AppButton';

export const Staffing = () => {
  const { t } = useTranslation();
  const candidatesList = (t(`staffing.candidates`, ``, { returnObjects: true }) ?? []) as unknown as Candidate[];

  return (
    <AppContainer>
      <StaffingWrapper>
        <AppTitle text={t('staffing.title')} />
        <div className="sides">
          <div className="img-side">
            <img
              width={500}
              height={750}
              loading="lazy"
              src={require('../../Assets/Images/staffing-2.webp')}
              alt="mission"
            />
          </div>
          <div className="img-side padded-side">
            <p dangerouslySetInnerHTML={{ __html: t('staffing.description') }} />
          </div>
        </div>
        <div className="sides">
          <div className="left">
            <Tabs>
              {candidatesList.map((cnd, i) => (
                <Tab key={`candidate-${i}`} label={cnd.name}>
                  <CandidateCard candidate={cnd} />
                </Tab>
              ))}
            </Tabs>
            <AppButton text={t('staffing.button')} to="/contact" width={300} />
          </div>
          <div className="img-side">
            <img
              width={500}
              height={550}
              loading="lazy"
              src={require('../../Assets/Images/staffing-1.webp')}
              alt="mission"
            />
          </div>
        </div>
      </StaffingWrapper>
    </AppContainer>
  );
};

const StaffingWrapper = styled.div`
  background-color: var(--white);
  strong {
    color: var(--black);
  }
  .left {
    flex: 2;
  }
  .img-side {
    flex: 1;
    img {
      max-width: 100%;
      max-height: calc(100vw * 1.2);
    }
  }
  .padded-side {
  }
  .sides {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin: var(--v-margin) 0;
    gap: 20px;
  }

  @media only screen and (max-width: 1100px) {
    .sides {
      flex-direction: column;
    }
  }
`;
