/* eslint-disable import/no-dynamic-require */
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppLanguages } from '../App';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value.toLowerCase();
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <LanguageSwitcherWrapper>
      <select onChange={handleLanguageChange} value={i18n.language}>
        {AppLanguages.map((lang, i) => (
          <option key={`language-${i}`} value={lang.slug} data-source={lang.flag}>
            {lang.name}
          </option>
        ))}
      </select>
    </LanguageSwitcherWrapper>
  );
};

const LanguageSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  select {
    border-radius: 8px;
    background-color: var(--white);
    border: none;
    color: var(--black);
    cursor: pointer;
    outline: none;
    min-height: 2em;
  }
  .lang-flag {
    margin-right: 5px;
  }
`;
