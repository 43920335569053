import { ContactFormObj } from '../Types/ContactFormObj';

const url = 'https://redowlsolutions.co.uk/send-email-22qeMccq43J9QXn8.php';

export const sendEmail = async (data: ContactFormObj) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('subject', data.subject ?? '');
  formData.append('phone', data.phone ?? '');
  formData.append('message', data.message ?? '');

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      //   const res = await response.json();
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error sending form data:', error);
    return false;
  }
};
