/* eslint-disable jsx-a11y/iframe-has-title */
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppTitle } from '../../Common/AppTitle';
import { AppButton } from '../../Common/AppButton';

export const Services = () => {
  const { t } = useTranslation();
  return (
    <ServicesWrapper>
      <AppTitle text={t('services.title')} />
      <div className="marginer" />
      <div className="splitter">
        <div className="text-inner-container">
          <AppTitle text={t('services.charte.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.charte.description') }} />
          <AppTitle text={t('services.design.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.design.description') }} />
        </div>
        <div className="img-side">
          <img loading="lazy" src={require('../../Assets/Images/service-mockup-450x700.webp')} alt="mockup" />
        </div>
      </div>
      <div className="splitter greyer reverse">
        <div className="img-side">
          <img loading="lazy" src={require('../../Assets/Images/service-site-450x700.webp')} alt="website" />
        </div>
        <div className="text-inner-container">
          <AppTitle text={t('services.sites.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.sites.description') }} />
          <AppTitle text={t('services.mobile.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.mobile.description') }} />
        </div>
      </div>
      <div className="splitter">
        <div className="text-inner-container">
          <AppTitle text={t('services.management.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.management.description') }} />
        </div>
        <div className="img-side">
          <img loading="lazy" src={require('../../Assets/Images/service-management-500x700.webp')} alt="management" />
        </div>
      </div>
      <div className="splitter greyer reverse">
        <div className="img-side">
          <img loading="lazy" src={require('../../Assets/Images/service-montage-400x550.webp')} alt="montage" />
        </div>
        <div className="text-inner-container">
          <AppTitle text={t('services.animation.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.animation.description') }} />
          <AppTitle text={t('services.video.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.video.description') }} />
        </div>
      </div>
      <div className="splitter">
        <div className="text-inner-container">
          <AppTitle text={t('services.modeling.title')} left type={2} />
          <p dangerouslySetInnerHTML={{ __html: t('services.modeling.description') }} />
        </div>
        <div className="img-side">
          <div className="iframe-container">
            <iframe
              src="https://my.spline.design/untitled-25798656eb91a114143f85e416c3d7c6/"
              width="100%"
              height="700px"
            />
          </div>
        </div>
      </div>
      <div className="centered-button">
        <AppButton text={t('services.button')} width={300} to="/contact" />
      </div>
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: var(--white);
  .splitter {
    padding: var(--v-margin) calc((100% - var(--max-app-width)) / 2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2%;
    .img-side {
      flex: 2;
      img {
        width: 100%;
      }
      .iframe-container {
        height: 600px;
        overflow: hidden;
        iframe {
          border: none;
        }
      }
    }
    .text-inner-container {
      max-width: 700px;
      flex: 3;
    }
    p {
      color: var(--text);
    }
  }
  .marginer {
    margin-top: calc(var(--v-margin) * -1);
  }
  .greyer {
    background-color: var(--light-grey);
  }
  @media only screen and (max-width: 1100px) {
    .splitter {
      padding: 0;
      flex-direction: column;
      padding: 0 var(--v-margin);
      box-sizing: border-box;
    }
    .splitter.reverse {
      flex-direction: column-reverse;
    }
  }
`;
