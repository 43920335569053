import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppTitle } from '../../Common/AppTitle';
import { AppButton } from '../../Common/AppButton';
import { ProductCard } from '../../Common/ProductCard';
import { AppContainer } from '../../Common/AppContainer';

export const Products = () => {
  const { t } = useTranslation();
  return (
    <AppContainer>
      <ProductsWrapper>
        <AppTitle text={t('products.title')} />
        <div className="cards">
          <ProductCard
            title={t('products.doc-crm.title')}
            description={t('products.doc-crm.description')}
            image={require('../../Assets/Images/product-crm.webp')}
            button={
              <div className="card-button">
                <AppButton text={t('products.virtual.button')} width={200} to="/contact" />
              </div>
            }
          />
          <ProductCard
            reverse
            title={t('products.virtual.title')}
            description={t('products.virtual.description')}
            image={require('../../Assets/Images/product-virtual.webp')}
            button={
              <div className="card-button">
                <AppButton text={t('products.virtual.button')} width={200} to="/contact" />
              </div>
            }
          />
        </div>
      </ProductsWrapper>
    </AppContainer>
  );
};

const ProductsWrapper = styled.div`
  background-color: var(--white);
  .cards {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .card-button {
    display: flex;
    justify-content: left;
    align-items: center;
  }
`;
