import { styled } from 'styled-components';
import { AppTitle } from './AppTitle';

interface ProductCardProps {
  title: string;
  description: string;
  image?: string;
  reverse?: boolean;
  button?: JSX.Element;
}
export const ProductCard = ({ title, description, image, reverse = false, button }: ProductCardProps) => (
  <ProductCardWrapper reverse={reverse}>
    {image && <div className="img-side" style={{ backgroundImage: `url('${image}')` }} />}
    <div className="content-side">
      <AppTitle text={title} type={2} left showUnderline={false} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
      {button}
    </div>
  </ProductCardWrapper>
);

const ProductCardWrapper = styled.div<{ reverse: boolean }>`
  position: relative;
  display: flex;
  ${({ reverse }) => `flex-direction: ${reverse ? `row-reverse` : 'row'};`};
  justify-content: center;
  align-items: stretch;
  border: 10px solid var(--light-grey);
  .img-side,
  .content-side {
    display: flex;
    flex-direction: column;
  }
  .img-side {
    flex: 2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .content-side {
    flex: 3;
    padding: var(--v-margin);
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    .img-side {
      min-height: 300px;
    }
    .content-side {
      padding: var(--v-margin-mobile);
    }
  }
`;
