/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';

interface AppTextInputProps {
  label?: string;
  currentVal?: string;
  showError?: boolean;
  textArea?: boolean;
  required?: boolean;
  onChange: (str: string) => void;
  placeholder?: string;
  disabled?: boolean;
  errorHint?: string;
  forceLTR?: boolean;
  [x: string]: any;
}
export const AppTextInput = ({
  label,
  currentVal,
  showError,
  textArea = false,
  required = true,
  onChange,
  placeholder,
  disabled,
  errorHint = 'Ce champ contient une erreur',
  forceLTR = false,
  ...rest
}: AppTextInputProps) => (
  <Wrapper {...rest}>
    {label && <label className="form-label">{label}</label>}
    <div className="input-container">
      {textArea ? (
        <textarea
          disabled={!!disabled}
          className={`form-control ${showError && 'error-input'}`}
          required={required}
          value={currentVal ?? ''}
          placeholder={placeholder ?? ''}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onChange((currentVal ?? '').trim());
          }}
        />
      ) : (
        <input
          disabled={!!disabled}
          className={`form-control ${showError && 'error-input'}`}
          required={required}
          type="text"
          style={forceLTR ? { direction: 'ltr' } : {}}
          value={currentVal ?? ''}
          placeholder={placeholder ?? ''}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onChange((currentVal ?? '').trim());
          }}
        />
      )}
    </div>
    {showError && errorHint && <div className="error-hint">{errorHint}</div>}
  </Wrapper>
);

const Wrapper = styled.div`
  /* margin-bottom: 20px; */
  * {
    box-sizing: border-box;
  }
  .form-label {
    color: var(--grey);
    text-transform: uppercase;
    font-size: 13px;
  }
  .form-control:focus {
    border-color: var(--secondary);
  }
  .form-control {
    &.error-input {
      color: var(--error);
      border-color: var(--error);
    }
    border: 1px solid var(--grey);
  }
  .error-hint {
    margin-bottom: 5px;
    font-size: 16px;
    color: var(--error);
  }
  input::placeholder {
    color: var(--grey);
    font-weight: 400;
  }
  .input-container {
    position: relative;
    margin-bottom: 4px;
    input {
      border-radius: 4px;
      color: var(--primary);
      font-size: 20px;
      font-weight: 600;
      width: 100%;
      display: block;
      margin: 0;
      height: 2em;
      padding: 8px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;
