import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

interface AppButtonProps {
  text: string;
  width?: number;
  color?: string;
  to?: string;
  disabled?: boolean;
  withShadow?: boolean;
  action?: () => void;
}
export const AppButton = ({ text, width, color, to, withShadow = false, disabled = false, action }: AppButtonProps) => (
  <AppButtonWrapper width={width} color={color} disabled={disabled} withShadow={withShadow} onClick={action}>
    {to ? <Link to={to}>{text}</Link> : <span>{text}</span>}
  </AppButtonWrapper>
);

const AppButtonWrapper = styled.button<{
  width: number | undefined;
  color: string | undefined;
  disabled: boolean;
  withShadow: boolean;
}>`
  * {
    font-family: var(--fjalla);
    color: var(--white);
  }
  cursor: pointer;
  text-transform: uppercase;
  min-height: 3em;
  font-size: 14px;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  max-width: 100%;
  ${({ width }) => `width: ${width ? `${width}px` : '100%'};`};
  ${({ withShadow }) => `box-shadow: ${withShadow ? `0 0 10px rgba(0,0,0,.2)` : ''};`};

  text-align: center;
  background-size: 500%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background-color: var(--grey);
  background-position: 0;
  ${({ disabled }) =>
    `background-image: ${
      disabled ? `` : 'linear-gradient(to left, var(--dark-green), var(--secondary), var(--dark-green))'
    };`};

  &:hover {
    background-position: 90% 0;
  }

  -o-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;

  a,
  a:active,
  a:visited {
    color: var(--white);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 2.5em;
    margin: 0;
    padding: 0;
  }
`;
