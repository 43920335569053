/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement, ReactNode, useEffect } from 'react';
import { styled } from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

interface AppPopOverProps {
  title?: string;
  open: boolean;
  close: () => void;
  children: ReactNode | ReactElement[];
}
export const AppPopOver = ({ title = '', open, close, children }: AppPopOverProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [close]);

  if (!open) {
    return null;
  }
  return (
    <AppPopOverWrapper>
      <div className="bg" onClick={close} />
      <div className="content-container">
        <div className="header">
          <span>{title}</span>
          <AiOutlineClose onClick={close} />
        </div>
        <div className="content">{children}</div>
      </div>
    </AppPopOverWrapper>
  );
};

const AppPopOverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-transparent);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  .bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .content-container {
    background-color: var(--white);
    width: 100%;
    max-width: 900px;
    max-height: 550px;
    z-index: 3;
    margin: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    border-radius: 8px;
    .header {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: var(--primary);
      padding: 0 20px;
      span {
        max-width: 90%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      * {
        color: var(--secondary);
        font-size: 18px;
      }
      svg {
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .content {
      width: 100%;
      max-width: 100%;
      max-height: 500px;
      height: 100%;
      object-fit: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1100px) {
    .content-container {
      max-width: 95%;
    }
  }
`;
