import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Logo width={300} />
      <div className="vertical-separator" />
      <p>{t('footer.rights')}</p>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: var(--dark);
  p {
    text-align: center;
    color: var(--white);
    font-size: 14px;
    padding: 10px;
  }
  .vertical-separator {
    height: 50px;
    width: 1px;
    background-color: white;
    margin: 0 auto;
    margin-top: -30px;
  }
`;
