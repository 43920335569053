import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppButton } from './AppButton';
import { AnimatedColorTitle } from './AnimatedColorTitle';

export const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <PageNotFoundWrapper>
      <div className="centered">
        <AnimatedColorTitle text={t('not-found.title')} fontSize={50} />
        <AppButton text={t('not-found.button')} width={300} to="/" />
      </div>
    </PageNotFoundWrapper>
  );
};

const PageNotFoundWrapper = styled.div`
  background-color: var(--primary);
  width: 100%;
  color: white;
  margin: calc(var(--v-margin) * -1) 0;
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
