import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

interface Service {
  title: string;
  description: string;
}
export const ServicesSection = () => {
  const { t } = useTranslation();

  const servicesList = (t(`home.services`, ``, { returnObjects: true }) ?? []) as unknown as Service[];

  return (
    <ServicesSectionWrapper id="services-section">
      {servicesList.map((ser, i) => (
        <div key={`service-${i}`} className="service-container">
          <span>0{i + 1}</span>
          <div className="content">
            <Link to="/services">
              <h2>{ser.title}</h2>
            </Link>
            <p dangerouslySetInnerHTML={{ __html: ser.description }} />
          </div>
        </div>
      ))}
    </ServicesSectionWrapper>
  );
};

const ServicesSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  padding: 100px var(--h-margin) var(--v-margin) var(--h-margin);
  row-gap: 40px;
  a,
  a:visited {
    color: var(--primary);
    text-decoration: none;
  }
  .service-container {
    width: 33%;
    position: relative;
    span {
      z-index: 0;
      font-size: 100px;
      position: absolute;
      font-weight: 1000;
      color: var(--grey);
      opacity: 0.2;
      top: -30px;
      left: 40px;
      font-family: var(--fjalla);
    }
    .content {
      padding: 0 40px;
      h2 {
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        padding-left: 40px;
        min-height: 60px;
        z-index: 1;
        position: relative;
        transition: all 300ms ease;
        &:hover {
          color: var(--dark-green);
        }
      }
      p {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    padding: var(--v-margin) var(--h-margin);
    .service-container {
      width: 100%;
      .content {
        padding: 0px 0px;
        width: 100%;
      }
    }
  }
`;
