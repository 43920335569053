import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Social } from './Social';
import { Logo } from './Logo';
import { LanguageSwitcher } from './LanguageSwitcher';
import { routerPages } from '../Helpers/RouteConfig';

interface MobileMenuProps {
  menuOpen: boolean;
  close: () => void;
}
export const MobileMenu = ({ menuOpen, close }: MobileMenuProps) => {
  const { t } = useTranslation();

  return (
    <MobileMenuWrapper menuOpen={menuOpen}>
      <div className="inner-container">
        <div className="logo-container">
          <Logo to="/" />
        </div>
        <div className="links">
          {routerPages.map((page, i) => (
            <NavLink
              key={`MobileMenu-link-${i}`}
              to={page.path}
              className={`${routerPages.length - 1 === i ? 'buttonied' : 'hover-underline-animation'}`}
              onClick={close}
            >
              {t(page.name)}
            </NavLink>
          ))}
        </div>
        <LanguageSwitcher />
        {/* <Social /> */}
      </div>
    </MobileMenuWrapper>
  );
};

const MobileMenuWrapper = styled.div<{ menuOpen: boolean }>`
  z-index: 3;
  position: fixed;
  background: var(--dark);
  overflow: hidden;
  top: 0;
  padding: 9px 0;
  left: 0;
  bottom: 0;
  ${({ menuOpen }) => `width: ${menuOpen ? `100%` : '0'};`};
  transition: all 300ms ease;
  .inner-container {
    width: 100vw;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4vh;
  }
  * {
    color: var(--white);
  }
  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1vh;
    flex: 1;
    max-height: 500px;

    a,
    a:visited {
      font-size: 24px;
      color: var(--white);
      text-transform: uppercase;
      font-family: var(--fjalla);
      text-decoration: none;
    }
  }
  .logo-container {
    max-height: 200px;
  }
`;
