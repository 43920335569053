import { ReactElement } from 'react';
import { styled } from 'styled-components';

interface AppContainerProps {
  children: ReactElement;
}
export const AppContainer = ({ children }: AppContainerProps) => <AppContainerWrapper>{children}</AppContainerWrapper>;

const AppContainerWrapper = styled.div`
  max-width: var(--max-app-width);
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--v-margin);
  box-sizing: border-box;
  @media only screen and (max-width: 1100px) {
  }
`;
