import { AppRoute } from '../Types/AppRoute';
import { Home } from '../Pages/Home/Home';
import { About } from '../Pages/About/About';
import { Contact } from '../Pages/Contact/Contact';
import { Services } from '../Pages/ServicesPage/ServicesPage';
import { Products } from '../Pages/Products/Products';
import { Staffing } from '../Pages/Staffing/Staffing';
import { Portfolio } from '../Pages/Portfolio/Portfolio';

export const routerPages: AppRoute[] = [
  {
    name: `home.menu-title`,
    path: '/',
    element: <Home />
  },
  {
    name: `about.menu-title`,
    path: '/about-us',
    element: <About />
  },
  {
    name: `services.menu-title`,
    path: '/services',
    element: <Services />
  },
  {
    name: `products.menu-title`,
    path: '/products',
    element: <Products />
  },
  {
    name: `staffing.menu-title`,
    path: '/staffing',
    element: <Staffing />
  },
  {
    name: `portfolio.menu-title`,
    path: '/portfolio',
    element: <Portfolio />
  },
  {
    name: `contact.menu-title`,
    path: '/contact',
    element: <Contact />
  }
];
