import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Candidate } from '../Types/Candidate';

interface CandidateCardProps {
  candidate: Candidate;
}
export const CandidateCard = ({ candidate }: CandidateCardProps) => {
  const { t } = useTranslation();
  return (
    <CandidateCardWrapper>
      <h3>{t('staffing.tech')}</h3>
      {candidate.tech.map((tech, i) => (
        <p key={`tech-${i}`}>{tech}</p>
      ))}
      <h3>{t('staffing.experiences')}</h3>
      {candidate.experiences.map((exp, i) => (
        <div key={`experience-${i}`} className="exp-container">
          <p>
            <strong>{`${exp.company} | ${exp.location}`}</strong>
            <span>{` | ${exp.position} | ${exp.period}`}</span>
          </p>
          <p dangerouslySetInnerHTML={{ __html: exp.description }} />
        </div>
      ))}
    </CandidateCardWrapper>
  );
};

const CandidateCardWrapper = styled.div`
  .exp-container {
    span {
      font-style: italic;
    }
  }
`;
