import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

interface LogoProps {
  width?: number;
  to?: string;
}
export const Logo = ({ width = 200, to }: LogoProps) =>
  to ? (
    <LogoWrapper>
      <Link to={to}>
        <img width={width} height={width * 0.3775} src={require('../Assets/Images/Logo-semi-white.webp')} alt="logo" />
      </Link>
    </LogoWrapper>
  ) : (
    <LogoWrapper>
      <img width={width} height={width * 0.3775} src={require('../Assets/Images/Logo-semi-white.webp')} alt="logo" />
    </LogoWrapper>
  );

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    max-width: 100%;
  }
`;
