export const headerHeight = 100;
export const contactEmail = 'contact@redowlsolutions.co.uk';
export const instagramLink = 'https://www.instagram.com/redowlsolutions.co.uk';
export const facebookLink = 'https://www.facebook.com/people/Pixel-Shades/100087884742930/';
export const linkedInLink = 'https://www.linkedin.com/company/pixel-shades/';
export const appCurrency = '€';

export const isValidEmail = (email: string | undefined) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (typeof email === 'string' && email.length) {
    return re.test(String(email).toLowerCase().trim());
  }
  return false;
};

export function importAll(r: any) {
  return r.keys().map(r);
}
export function writePrice(price: number) {
  return `${price} ${appCurrency}`;
}
