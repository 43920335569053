import { Dispatch, SetStateAction } from 'react';
import { styled } from 'styled-components';
import { headerHeight } from '../Helpers/Constants';

interface MenuIconProps {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}
export const MenuIcon = ({ menuOpen, setMenuOpen }: MenuIconProps) => {
  const toggleMenu = (): void => {
    setMenuOpen((v) => !v);
  };

  return (
    <MenuIconWrapper className={`${menuOpen ? 'menu-open' : ''}`}>
      <svg className="vbp-header-menu-button__svg" onClick={toggleMenu}>
        <line x1="0" y1="50%" x2="100%" y2="50%" className="top" shapeRendering="crispEdges" />
        <line x1="0" y1="50%" x2="100%" y2="50%" className="middle" shapeRendering="crispEdges" />
        <line x1="0" y1="50%" x2="100%" y2="50%" className="bottom" shapeRendering="crispEdges" />
      </svg>
    </MenuIconWrapper>
  );
};

const MenuIconWrapper = styled.div`
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  display: none;
  justify-content: center;
  align-items: center;
  height: ${headerHeight}px;
  .vbp-header-menu-button__svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: scaleX(-1);
  }

  .vbp-header-menu-button__svg line {
    stroke: var(--white);
    stroke-dasharray: 100%;
    stroke-dashoffset: 0%;
    transition:
      transform 0.3s,
      stroke-dashoffset 0.4s;
    transform-origin: center;
    stroke-width: 3px;
  }

  .vbp-header-menu-button__svg .top {
    transform: translateY(-35%);
    -webkit-transform: translateY(-35%);
    -moz-transform: translateY(35%);
    -ms-transform: translateY(35%);
    -o-transform: translateY(35%);
  }

  .vbp-header-menu-button__svg .middle {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transition:
      opacity 0.3s,
      transform 0.3s;
    -webkit-transition:
      opacity 0.3s,
      transform 0.3s;
    -moz-transition:
      opacity 0.3s,
      transform 0.3s;
    -ms-transition:
      opacity 0.3s,
      transform 0.3s;
    -o-transition:
      opacity 0.3s,
      transform 0.3s;
  }

  .vbp-header-menu-button__svg .bottom {
    transform: translateY(33%);
    -webkit-transform: translateY(33%);
    -moz-transform: translateY(33%);
    -ms-transform: translateY(33%);
    -o-transform: translateY(33%);
    stroke-dasharray: 100%;
    stroke-dashoffset: 9px;
  }
  .vbp-header-menu-button__svg:hover .bottom {
    stroke-dashoffset: 0%;
  }
  /*** Mode Croix ***/
  &.menu-open {
    position: fixed;
    .vbp-header-menu-button__svg .top {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      stroke-linecap: round;
    }

    .vbp-header-menu-button__svg .middle {
      transform: scaleX(0.1);
      -webkit-transform: scaleX(0.1);
      -moz-transform: scaleX(0.1);
      -ms-transform: scaleX(0.1);
      -o-transform: scaleX(0.1);
      opacity: 0;
    }

    .vbp-header-menu-button__svg line.bottom {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      stroke-linecap: round;
    }

    .vbp-header-menu-button__svg .bottom {
      stroke-dashoffset: 0%;
    }
  }

  @media only screen and (max-width: 1100px) {
    display: flex;
  }
`;
