import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../../Common/Logo';
import { AppButton } from '../../../Common/AppButton';
import { ScrollDownButton } from '../../../Common/ScrollDownButton';

export const HomeHero = () => {
  const { t } = useTranslation();
  return (
    <HomeHeroWrapper>
      <div className="logo-container">
        <Logo width={400} />
      </div>
      <div className="button-container">
        <h1 dangerouslySetInnerHTML={{ __html: t('home.hero.title') }} />
        <AppButton withShadow text={t('home.hero.button')} width={300} to="/contact" />
        <ScrollDownButton to="#services-section" />
      </div>
    </HomeHeroWrapper>
  );
};

const floatingEnd = '10%';
const floatingStart = '0';
const animationDuration = '8s';

const HomeHeroWrapper = styled.div`
  height: 600px;
  background-color: var(--primary);
  background: linear-gradient(rgba(23, 23, 23, 0.1), rgba(0, 0, 0, 0.2)),
    url(${require('../../../Assets/Images/pixelshades_wallpaper-scaled.webp')});
  background-position: center;
  background-position-y: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    color: white;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    font-size: 40px;
    text-align: center;
  }
  .button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .logo-container {
    height: 50%;
    -webkit-animation: float ${animationDuration} ease infinite;
    -moz-animation: float ${animationDuration} ease infinite;
    -ms-animation: float ${animationDuration} ease infinite;
    -o-animation: float ${animationDuration} ease infinite;
    animation: float ${animationDuration} ease infinite;
  }
  @keyframes float {
    0% {
      -ms-transform: translateY(${floatingStart});
      -moz-transform: translateY(${floatingStart});
      -webkit-transform: translateY(${floatingStart});
      -o-transform: translateY(${floatingStart});
      transform: translateY(${floatingStart});
    }

    50% {
      -ms-transform: translateY(${floatingEnd});
      -moz-transform: translateY(${floatingEnd});
      -webkit-transform: translateY(${floatingEnd});
      -o-transform: translateY(${floatingEnd});
      transform: translateY(${floatingEnd});
    }

    100% {
      -ms-transform: translateY(${floatingStart});
      -moz-transform: translateY(${floatingStart});
      -webkit-transform: translateY(${floatingStart});
      -o-transform: translateY(${floatingStart});
      transform: translateY(${floatingStart});
    }
  }

  @media only screen and (max-width: 1100px) {
    height: 400px;
    .logo-container {
      max-width: 80%;
      margin: 0 auto;
    }
    h1 {
      font-size: 24px;
    }
  }
`;
