export interface ContactFormObj {
  email: string;
  name: string;
  subject?: string;
  phone?: string;
  message?: string;
}
export const emptyContactFormObj: ContactFormObj = {
  email: '',
  name: '',
  subject: '',
  phone: '',
  message: ''
};
