import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routerPages } from '../Helpers/RouteConfig';

export const Menu = () => {
  const { t } = useTranslation();
  return (
    <MenuWrapper>
      {routerPages.map((page, i) => (
        <NavLink
          key={`menu-link-${i}`}
          to={page.path}
          className={`${routerPages.length - 1 === i ? 'buttonied' : 'hover-underline-animation'}`}
        >
          {t(page.name)}
        </NavLink>
      ))}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  a,
  a:hover {
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--fjalla);
  }
  a.active {
    color: var(--secondary);
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
    line-height: 2.5em;
  }

  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--secondary);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
