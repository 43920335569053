import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { AnimatedColorTitle } from '../../../Common/AnimatedColorTitle';
import { AppButton } from '../../../Common/AppButton';

export const SolutionSection = () => {
  const { t } = useTranslation();

  return (
    <SolutionSectionWrapper>
      <div className="text-side side">
        <div className="text-inner-container">
          <AnimatedColorTitle text={t('home.solutions.title')} fontSize={50} />
          <p>{t('home.solutions.description')}</p>
          <AppButton text={t('home.solutions.button')} width={200} to="/services" />
        </div>
      </div>
      <div className="img-side side">
        <div className="gradiant-overlay" />
      </div>
    </SolutionSectionWrapper>
  );
};

const SolutionSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .text-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 5;
    flex-shrink: 0;
    gap: 20px;
    height: 100%;
    .text-inner-container {
      padding: 0 15%;
      margin: 0;
      height: 100%;
      p {
        max-width: 400px;
      }
    }
  }
  .side {
    display: flex;
  }
  .img-side {
    flex: 3;
    background-image: url(${require('../../../Assets/Images/women-in-tech.webp')});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 500px;
    display: block;
    .gradiant-overlay {
      width: 50%;
      height: 100%;
      background: var(--grey);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(255, 255, 255, 0) 100%);
    }
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
    margin-bottom: var(--v-margin);
    .text-side {
      text-align: center;
      .text-inner-container {
        padding: 0 var(--h-margin);
      }
      button {
        margin: 0 auto;
      }
    }
    .img-side {
      background-position: center;
      .gradiant-overlay {
        display: none;
      }
    }
  }
`;
