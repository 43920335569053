import { styled } from 'styled-components';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PageNotFound } from './PageNotFound';
import { routerPages } from '../Helpers/RouteConfig';
import { Home } from '../Pages/Home/Home';

export const Navigator = () => {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.pathname]);

  return (
    <NavigatorWrapper>
      <Routes>
        <Route index element={<Home />} />
        {routerPages.map((page, i) => (
          <Route key={`route-${i}`} path={page.path} element={page.element} />
        ))}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </NavigatorWrapper>
  );
};
const NavigatorWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: var(--v-margin) 0;
  background-color: var(--white);

  @media only screen and (max-width: 1100px) {
    padding: var(--v-margin-mobile) 0;
  }
`;
