/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { styled } from 'styled-components';

interface TabProps {
  label: string;
  children: React.ReactNode;
}

interface TabButtonsProps {
  buttons: string[];
  changeTab: (tab: string) => void;
  activeTab: string;
}

const TabButtons: React.FC<TabButtonsProps> = ({ buttons, changeTab, activeTab }) => (
  <div className="tab-buttons">
    {buttons.map((button) => (
      <button
        type="button"
        key={button}
        className={button === activeTab ? 'tabs-button active' : 'tabs-button '}
        onClick={() => changeTab(button)}
      >
        {button}
      </button>
    ))}
  </div>
);

const Tab: React.FC<TabProps> = ({ children }) => <>{children}</>;

interface TabsProps {
  children: React.ReactNode;
  setCurrentTab?: Dispatch<SetStateAction<string>>;
}

const Tabs: React.FC<TabsProps> = ({ children, setCurrentTab }) => {
  const initialActiveTab = (React.Children.toArray(children)[0] as React.ReactElement<TabProps>).props.label;
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const changeTab = (tab: string) => {
    setActiveTab(tab);
    if (setCurrentTab) {
      setCurrentTab(tab);
    }
  };

  let content;
  const buttons: string[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement<TabProps>(child)) {
      buttons.push(child.props.label);
      if (child.props.label === activeTab) {
        content = child.props.children;
      }
    }
  });

  return (
    <TabsWrapper>
      <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab} />
      <div className="tab-content">{content}</div>
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  .tabs-button {
    margin-right: 15px;
    font-size: 20px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--primary);
    transition: all ease-in-out 0.2s;
    border-bottom: 2px solid transparent;
    font-family: var(--fjalla);
  }
  .active {
    border-bottom: 2px solid var(--primary);
    color: var(--dark-green);
  }
`;
export { Tabs, Tab };
