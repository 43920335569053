import { styled } from 'styled-components';

interface AppTitleProps {
  text: string;
  type?: 1 | 2;
  left?: boolean;
  showUnderline?: boolean;
}
export const AppTitle = ({ text, type, left = false, showUnderline = true }: AppTitleProps) => (
  <AppTitleWrapper style={{ alignItems: left ? 'left' : 'center' }}>
    {type === 2 ? (
      <h2 className="linear-wipe" style={{ fontSize: type === 2 ? 35 : '' }}>
        {text}
      </h2>
    ) : (
      <h1 className="linear-wipe">{text}</h1>
    )}
    {showUnderline && (
      <div className="underlines-container">
        <div className="underline1" />
        <div className="underline2" />
      </div>
    )}
  </AppTitleWrapper>
);

const AppTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0;
  h1,
  h2 {
    font-weight: 400;
    font-size: 50px;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
  .underlines-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    .underline1 {
      width: 45px;
      height: 2px;
      display: inline-block;
      background-color: var(--dark-green);
    }
    .underline2 {
      width: 15px;
      margin-left: 5px;
      height: 2px;
      background-color: var(--light-green);
      display: inline-block;
    }
  }
  @media only screen and (max-width: 1100px) {
    h2 {
      font-size: 30px;
    }
  }
`;
