/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useState } from 'react';
import { AppTitle } from '../../Common/AppTitle';
import { AppContainer } from '../../Common/AppContainer';
import { importAll } from '../../Helpers/Constants';
import { Tab, Tabs } from '../../Common/Tabs';
import { PortfolioTabs } from '../../Types/PortfolioTabs';
import { AppPopOver } from '../../Common/AppPopOver';

interface Website {
  link: string;
  img: string;
}
const logos = importAll(require.context('../../Assets/Portfolio/Logos', false, /\.(png|jpe?g|svg|webp)$/));
const threeD = importAll(require.context('../../Assets/Portfolio/3D', false, /\.(png|jpe?g|svg|webp)$/));
const ai = importAll(require.context('../../Assets/Portfolio/AI', false, /\.(png|jpe?g|svg|webp)$/));
const printables = importAll(require.context('../../Assets/Portfolio/Imprimables', false, /\.(png|jpe?g|svg|webp)$/));
const others = importAll(require.context('../../Assets/Portfolio/Other', false, /\.(png|jpe?g|svg|webp)$/));

const websites: Website[] = [
  { link: 'https://alchimiepescalune.fr/', img: require('../../Assets/Portfolio/Websites/alchimiepescalune.webp') },
  { link: 'https://alphagreencbd.eu/', img: require('../../Assets/Portfolio/Websites/alphagreencbd.webp') },
  { link: 'https://arts-clinic.com/', img: require('../../Assets/Portfolio/Websites/artsclinic.webp') },
  { link: 'https://lemint.fr/ouest', img: require('../../Assets/Portfolio/Websites/lemint.webp') },
  { link: 'https://www.himvestgroup.com/', img: require('../../Assets/Portfolio/Websites/himvestgroup.webp') },
  { link: 'http://effervosens.ma/', img: require('../../Assets/Portfolio/Websites/effervosens.webp') },
  { link: 'https://www.restaurant-zaeka.com/', img: require('../../Assets/Portfolio/Websites/zaeka.webp') },
  { link: 'https://glamfitness.fr/', img: require('../../Assets/Portfolio/Websites/glamfitness.webp') }
];
const videos = [
  'https://youtu.be/0gWUQ-9d3FA',
  'https://youtu.be/1hox336PjNs',
  'https://youtu.be/hnduynI_iNA',
  'https://youtu.be/QXONLI3hSN8',
  'https://youtu.be/DT0R_BoohOA',
  'https://youtu.be/5JMpWe3w8kI',
  'https://youtu.be/ejGJBJj2pks',
  'https://youtu.be/bbcjNW27d2k',
  'https://youtu.be/F4rb4sAHx4U',
  'https://youtu.be/KJI3cFRcE38',
  'https://youtu.be/TLZIA7Eknm4',
  'https://youtu.be/d0bqpL9krAg',
  'https://youtu.be/meoHHdL6fAM',
  'https://youtu.be/ex79zwMbqvI',
  'https://youtu.be/YTS0XOZd_is',
  'https://youtu.be/B_QW5Xo31_4',
  'https://youtu.be/HU8P_Cx0H18'
];

export const Portfolio = () => {
  const { t } = useTranslation();
  const tabs = (t(`portfolio.tabs`, ``, { returnObjects: true }) ?? []) as unknown as PortfolioTabs;
  const [currentTab, setCurrentTab] = useState<string>('Websites');
  const [popOverImg, setPopOverImg] = useState<string>();
  const [popOverLink, setPopOverLink] = useState<string>();

  const youTubeGetID = (url: string) => {
    const p = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return p[2] !== undefined ? p[2].split(/[^0-9a-z_\-]/i)[0] : p[0];
  };

  const close = () => {
    setPopOverLink(undefined);
    setPopOverImg(undefined);
  };
  const openPopOver = (image: string) => {
    setPopOverImg(image);
  };
  const getImages = () => {
    switch (currentTab) {
      case tabs.threeD:
        return threeD;
      case tabs.ai:
        return ai;
      case tabs.printables:
        return printables;
      case tabs.others:
        return others;
      default:
        return logos;
    }
  };
  return (
    <AppContainer>
      <PortfolioWrapper>
        <AppTitle text={t('portfolio.title')} />
        <p className="sub-title">{t('portfolio.sub-title')}</p>

        <Tabs setCurrentTab={setCurrentTab}>
          {Object.entries(tabs).map((tab, i) =>
            tab[1] === 'Videos' ? (
              <Tab key={`portfolio-tab-${i}`} label="Videos">
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} className="masonry-container">
                  <Masonry gutter="10px">
                    {videos.map((vid: string, k: number) => (
                      <iframe
                        key={`video-${k}`}
                        src={`https://www.youtube.com/embed/${youTubeGetID(vid)}`}
                        title="YouTube video player"
                        allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </Tab>
            ) : tab[1] === 'Websites' ? (
              <Tab key={`portfolio-tab-${i}`} label="Websites">
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} className="masonry-container">
                  <Masonry gutter="10px">
                    {websites.map((site: Website, k: number) => (
                      <img
                        onClick={() => setPopOverLink(site.link)}
                        key={k}
                        src={site.img}
                        style={{ width: '100%', display: 'block', cursor: 'pointer' }}
                        alt="portfolio-img"
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </Tab>
            ) : (
              <Tab key={`portfolio-tab-${i}`} label={tab[1]}>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }} className="masonry-container">
                  <Masonry gutter="10px">
                    {getImages().map((image: string, j: number) => (
                      <img
                        onClick={() => openPopOver(image)}
                        key={j}
                        src={image}
                        style={{ width: '100%', display: 'block', cursor: 'pointer' }}
                        alt="portfolio-img"
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </Tab>
            )
          )}
        </Tabs>
        <AppPopOver
          title={popOverImg?.replace('/static/media/', '')}
          open={!!popOverImg || !!popOverLink}
          close={close}
        >
          {popOverImg && <img loading="lazy" src={popOverImg} alt="portfolio-img" className="pop-over-img" />}
          {popOverLink && (
            <a
              target="_blank"
              href={popOverLink}
              rel="noreferrer"
              aria-label="portfolio website link"
              title="portfolio website link"
            >
              {t('portfolio.go-external')}
            </a>
          )}
        </AppPopOver>
      </PortfolioWrapper>
    </AppContainer>
  );
};

const PortfolioWrapper = styled.div`
  background-color: var(--white);
  * {
    transition: all 300ms ease;
  }
  .pop-over-img {
    max-width: 100%;
    max-height: 100%;
  }
  .sub-title {
    width: 100%;
    text-align: center;
  }
  .masonry-container {
    margin-top: 10px;
  }
  iframe {
    border: none;
    height: 200px;
    &:hover {
      box-shadow: 0px 0px 20px black;
      transform: scale(1.2);
    }
  }
`;
