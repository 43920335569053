import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppTitle } from '../../Common/AppTitle';
import { AppButton } from '../../Common/AppButton';
import { AppContainer } from '../../Common/AppContainer';

export const About = () => {
  const { t } = useTranslation();
  return (
    <AppContainer>
      <AboutWrapper>
        <AppTitle text={t('about.title')} />
        <div className="marginer" />
        <div className="splitter">
          <div className="text-inner-container">
            <AppTitle text={t('about.mission.title')} left type={2} />
            <p dangerouslySetInnerHTML={{ __html: t('about.mission.description') }} />
            <div className="spacer" />
            <AppTitle text={t('about.values.title')} left type={2} />
            <p dangerouslySetInnerHTML={{ __html: t('about.values.description') }} />
          </div>
          <div className="img-side">
            <img
              width={500}
              height={550}
              loading="lazy"
              src={require('../../Assets/Images/mission-500x650.webp')}
              alt="mission"
            />
          </div>
        </div>
        <div className="spacer" />
        <div className="splitter reverse">
          <div className="img-side">
            <img
              width={500}
              height={300}
              loading="lazy"
              src={require('../../Assets/Images/atout-500x350.webp')}
              alt="atout"
            />
          </div>
          <div className="text-inner-container">
            <AppTitle text={t('about.atout.title')} left type={2} />
            <p dangerouslySetInnerHTML={{ __html: t('about.atout.description') }} />
          </div>
        </div>

        <div className="centered-button">
          <AppButton text={t('about.atout.button')} width={300} to="/services" />
        </div>
      </AboutWrapper>
    </AppContainer>
  );
};

const AboutWrapper = styled.div`
  .marginer {
    margin-top: calc(var(--v-margin) * -1);
  }
  .splitter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2%;
    padding: var(--v-margin) 0;
    .img-side {
      flex: 2;
      img {
        max-width: 100%;
        max-height: 600px;
      }
    }
    .text-inner-container {
      max-width: 700px;
      flex: 3;
    }
    p {
      color: var(--text);
    }
  }
  .spacer {
    width: 100%;
    height: 30px;
  }
  @media only screen and (max-width: 1100px) {
    .splitter {
      padding: 0;
      flex-direction: column;
    }
    .splitter.reverse {
      flex-direction: column-reverse;
    }
  }
`;
