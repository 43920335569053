import { styled } from 'styled-components';

interface ScrollDownButtonProps {
  to: string;
}
export const ScrollDownButton = ({ to }: ScrollDownButtonProps) => (
  <ScrollDownButtonWrapper>
    <a href={to}>
      <div id="scroll-down-animation">
        <span className="mouse">
          <span className="move" />
        </span>
        <p>Scroll Down</p>
      </div>
    </a>
  </ScrollDownButtonWrapper>
);

const ScrollDownButtonWrapper = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  padding: 10px 0;
  h2 {
    color: #fff;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 200;
    font-size: 12px;
  }

  .mouse {
    margin: 0 auto;
    display: block;
    border-radius: 50px;
    border: 2px solid #fff;
    height: 60px;
    width: 30px;
    position: relative;
  }

  .move {
    position: absolute;
    background-color: #fff;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: move 2s linear infinite;
  }

  a,
  a:visited {
    text-decoration: none;
  }
  p {
    color: transparent;
    text-align: center;
    width: 100%;
    margin-top: -40px;
  }
  @keyframes move {
    0% {
      transform: translate(-50%, 10px);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, 30px);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 50px);
      opacity: 0;
    }
  }
`;
